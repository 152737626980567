<template>
  <a-row style="margin: -0.5rem">
    <a-col :span="24" :lg="12" class="mt-4">
      <div class="card m-2">
        <h2>Time reporting</h2>
        <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Report type">
          <a-select v-model="reportType" placeholder="Select a report type" class="w-100">
            <a-select-option :value="ReportType.TIME">Time Report</a-select-option>
            <a-select-option :value="ReportType.VACATION">Vacation Report</a-select-option>
            <a-select-option :value="ReportType.SICK">Sick Report</a-select-option>
            <a-select-option :value="ReportType.PARENTAL">Parental Report</a-select-option>
            <a-select-option :value="ReportType.ABSENCE">Leave of absence</a-select-option>
            <a-select-option v-if="!disableDetachedTimereport" :value="ReportType.DETACHEDTIME"
              >Time report (without project)</a-select-option
            >
          </a-select>
        </a-form-item>
        <p v-if="reportType === ReportType.DETACHEDTIME">
          N.B. Now reporting time not associated with a project. Use this report type only when
          instructed to.
        </p>
        <a-divider />

        <add-time-report
          v-if="
            user &&
            calendarReports &&
            reportType == ReportType.TIME &&
            !(loadingCostCenters || loadingUserRoles)
          "
          :user-roles="userRoles"
          :user="user"
          :acl="baseAcl"
          :cost-centers="costCenters"
          :reports="calendarReports"
          :last-reported-role-id="latestReport && latestReport.role ? latestReport.role.id : null"
          :last-reported-hourly-rate="
            latestReport && latestReport.hourly_rate ? latestReport.hourly_rate : null
          "
          :selected-day="selectedDay"
          :report-to-copy-from="reportToCopyFrom"
          @store-report="(report) => handleReportWithBusinessDayCheck(report, handleStoreReport)"
          @day-selected="(date) => handleSelectedDate(date)"
        />

        <add-vacation-report
          v-if="reportType == ReportType.VACATION"
          :acl="baseAcl"
          :user="user"
          :user-vacation-and-absence-reports="userVacationAndAbsenceReports"
          :selected-day="selectedDay"
          @store-report="(report) => handleReportWithBusinessDayCheck(report, handleStoreReport)"
          @day-selected="(date) => handleSelectedDate(date)"
        />

        <add-absence-report
          v-if="reportType == ReportType.ABSENCE"
          :acl="baseAcl"
          :user="user"
          :selected-day="selectedDay"
          :user-vacation-and-absence-reports="userVacationAndAbsenceReports"
          @store-report="(report) => handleReportWithBusinessDayCheck(report, handleStoreReport)"
          @day-selected="(date) => handleSelectedDate(date)"
        />

        <add-sick-report
          v-if="reportType == ReportType.SICK"
          :user="user"
          :selected-day="selectedDay"
          @store-report="(report) => handleReportWithBusinessDayCheck(report, handleStoreReport)"
          @day-selected="(date) => handleSelectedDate(date)"
        />

        <add-parental-report
          v-if="reportType == ReportType.PARENTAL"
          :user="user"
          :selected-day="selectedDay"
          :user-parental-reports="userParentalReports"
          @store-report="(report) => handleReportWithBusinessDayCheck(report, handleStoreReport)"
          @day-selected="(date) => handleSelectedDate(date)"
        />

        <add-detached-time-report
          v-if="
            user &&
            calendarReports &&
            reportType == ReportType.DETACHEDTIME &&
            !(loadingCostCenters || loadingUserRoles)
          "
          :user-roles="userRoles"
          :user="user"
          :acl="baseAcl"
          :cost-centers="
            costCenters.filter((cc) => cc.use_cases.includes(CostCenterUseCase.DETACHEDTIME))
          "
          :reports="calendarReports"
          :last-reported-role-id="latestReport && latestReport.role ? latestReport.role.id : null"
          :last-reported-hourly-rate="
            latestReport && latestReport.hourly_rate ? latestReport.hourly_rate : null
          "
          :selected-day="selectedDay"
          :report-to-copy-from="reportToCopyFrom"
          @store-report="(report) => handleReportWithBusinessDayCheck(report, handleStoreReport)"
          @day-selected="(date) => handleSelectedDate(date)"
        />
      </div>

      <calendar
        :calendar-reports="calendarReports"
        :public-holidays="publicHolidays"
        :user="user"
        :selected-day="selectedDay"
        @get-month="(month) => getCalendarReportsAndPublicHolidaysPerMonth(month)"
        @day-clicked="(date) => handleSelectedDate(date)"
      />
    </a-col>

    <a-col :span="24" :lg="12" class="mt-4">
      <div class="card m-2">
        <colleague-survey @save-survey-response="(survey) => handleStoreSurveyResponse(survey)" />
      </div>
      <div class="card m-2">
        <h2>Latest reports</h2>
        <div class="d-flex justify-content-between my-4 align-items-center">
          <a-button-group>
            <a-button
              :type="currentReportsScope === 'my-reports' ? 'primary' : 'default'"
              @click="handleChangeReportScope('my-reports')"
            >
              My Reports
            </a-button>
            <a-button
              :type="currentReportsScope === 'all-reports' ? 'primary' : 'default'"
              @click="handleChangeReportScope('all-reports')"
            >
              All Reports
            </a-button>
          </a-button-group>

          <span class="d-flex align-items-center">
            Show vacation
            <a-switch :value="withVacation" class="ml-2" @change="handleShowVacation" />
          </span>
        </div>

        <reports-list
          :user-reports="userReports"
          :user-roles="userRoles"
          :loading-reports="loadingReports"
          :user="user"
          :user-vacation-and-absence-reports="userVacationAndAbsenceReports"
          :user-parental-reports="userParentalReports"
          :public-holidays="publicHolidays"
          :cost-centers="costCenters"
          :show-users="currentReportsScope === 'all-reports'"
          :show-roles="currentReportsScope === 'my-reports'"
          :show-comments="currentReportsScope === 'my-reports'"
          :show-expand-comment="currentReportsScope === 'all-reports'"
          :show-all-reports-link="currentReportsScope === 'all-reports'"
          :show-my-reports-link="currentReportsScope === 'my-reports'"
          @update-report="
            (updatedReport) => handleReportWithBusinessDayCheck(updatedReport, handleUpdateReport)
          "
          @destroy-report="(reportId) => handleDestroyReport(reportId)"
          @copy-report-to-form="copyReportToForm"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, onMounted, onUnmounted, watch } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { apiGetUser, apiGetUserRoles } from "@/modules/users/_utils/api";
import AddParentalReport from "../_components/AddParentalReport.vue";
import AddVacationReport from "../_components/AddVacationReport.vue";
import AddAbsenceReport from "../_components/AddAbsenceReport.vue";
import { apiGetCostCenters } from "../../cost_centers/_utils/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import ColleagueSurvey from "../_components/ColleagueSurvey.vue";
import { CostCenterInterface, CostCenterUseCase } from "../../cost_centers/types";
import AddTimeReport from "../_components/AddTimeReport.vue";
import AddDetachedTimeReport from "../_components/AddDetachedTimeReport.vue";
import AddSickReport from "../_components/AddSickReport.vue";
import ReportsList from "../_components/ReportsList.vue";
import Calendar from "../_components/Calendar.vue";
import { RoleInterface } from "../../roles/types";
import { apiGetReports } from "../_utils/api";
import { isWeekendDay } from "../../../utils";
import moment from "@/date";
import {
  PublicHolidayInterface,
  ReportInterface,
  ReportPayloadInterface,
  ReportType,
  SurveyPayloadInterface,
} from "../types";
import { useReportsStore } from "@/modules/reports/_store";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  tab: { type: String, default: undefined },
});

// Pinia
const authenticationStore = useAuthenticationStore();
const reportsStore = useReportsStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
const userReports = computed<Map<number, any>>(() => reportsStore.reports);
const calendarReports = computed<Map<String, Array<ReportInterface>>>(
  () => reportsStore.calendarReports
);
const publicHolidays = computed<Map<String, Array<PublicHolidayInterface>>>(
  () => reportsStore.publicHolidays
);

// Data properties
const userVacationAndAbsenceReports = ref<Array<ReportInterface>>([]);
const reportToCopyFrom = ref<ReportInterface | undefined>(undefined);
const currentMonth = ref<string>(moment().format("YYYY.M"));
const userParentalReports = ref<Array<ReportInterface>>([]);
const user = ref<ProfileInterface | undefined>(undefined);
const costCenters = ref<Array<CostCenterInterface>>([]);
const currentReportsScope = ref<string>("my-reports");
const userRoles = ref<Array<RoleInterface>>([]);
const reportType = ref<string>(ReportType.TIME);
const loadingCostCenters = ref<boolean>(true);
const loadingUserRoles = ref<boolean>(true);
const loadingReports = ref<boolean>(false);
const withVacation = ref<boolean>(false);
const selectedDay = ref<string>(moment().format("YYYY-MM-DD"));
const currentPage = ref<number>(1);
const pageSize = ref<number>(7);
const reportTypes = ref<Array<ReportType>>([
  ReportType.TIME,
  ReportType.SICK,
  ReportType.VACATION,
  ReportType.PARENTAL,
  ReportType.ABSENCE,
  ReportType.DETACHEDTIME,
]);
const disableDetachedTimereport = import.meta.env.VITE_DISABLE_DETACHED_TIME_REPORT;

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy?.$message;
const $confirm = instance?.proxy?.$confirm;

// Computed properties
const latestReport = computed((): any => {
  const date = moment();
  return userReports.value.get(1)
    ? userReports.value.get(1).find(
        (r: any) =>
          r.type === ReportType.TIME &&
          r.user &&
          r.user.id === profile.value?.id &&
          userRoles.value
            .filter(
              (role) =>
                date.isSameOrAfter(role.start_date, "day") &&
                (!role.end_date || date.isSameOrBefore(role.end_date, "day"))
            )
            .map((r) => r.id)
            .includes(r.role.id)
      )
    : undefined;
});

// Lifecycle hooks
onMounted(() => {
  emitter.on("reload-all-reports", reloadAllReports);
  emitter.on("reload-user", reloadUser);

  apiGetUser(profile.value?.id as number).then((res: any) => (user.value = res.data.data));

  reloadAllReports();

  apiGetUserRoles(profile.value?.id as number).then((res: any) => {
    userRoles.value = res.data.data;
    loadingUserRoles.value = false;

    emitter.emit("reloaded-user-roles");
  });

  apiGetCostCenters().then((res: any) => {
    costCenters.value = res.data.data;
    loadingCostCenters.value = false;
  });
});

onUnmounted(() => {
  emitter.off("reload-all-reports", reloadAllReports);
  emitter.off("reload-user", reloadUser);
});

// Watchers
watch(
  () => props.tab,
  (newProp, oldProp) => {
    if (newProp === "reports" && oldProp !== "reports") {
      reloadAllReports();
    }
  }
);

// Component methods
const getCalendarReportsAndPublicHolidaysPerMonth = (month: any, reload = false): void => {
  currentMonth.value = month;
  reportsStore.getCalendarReports(month);
  reportsStore.getPublicHolidays(month);
};

const getRelevantVacationAndAbsenceReports = (): void => {
  apiGetReports({
    user_id: [profile.value?.id],
    start_date: moment().subtract(2, "M").format("YYYY-MM-DD"),
    types: [ReportType.VACATION, ReportType.ABSENCE],
  }).then((res: any) => (userVacationAndAbsenceReports.value = res.data.data));
};

const getRelevantParentalReports = (): void => {
  apiGetReports({
    user_id: [profile.value?.id],
    start_date: moment().subtract(2, "M").format("YYYY-MM-DD"),
    types: [ReportType.PARENTAL],
  }).then((res: any) => (userParentalReports.value = res.data.data));
};

const handleSelectedDate = (date: string): void => {
  currentMonth.value = moment(date).format("YYYY.M");
  selectedDay.value = date;
  emitter.emit("selected-date", selectedDay.value);
};

const handleChangeReportScope = (key: any): void => {
  if (key !== currentReportsScope.value) {
    currentReportsScope.value = key;
    reloadAllReports();
  }
};

const handleShowVacation = (showVacation: boolean): void => {
  withVacation.value = showVacation;
  reloadAllReports();
};

const reloadUser = (): void => {
  apiGetUser(profile.value?.id as number).then((res: any) => (user.value = res.data.data));
};

const reloadAllReports = (): void => {
  loadingReports.value = true;
  let params = {
    user_id: currentReportsScope.value === "my-reports" ? [profile.value?.id] : [],
    page: currentPage.value,
    page_size: pageSize.value,
    types: withVacation.value
      ? reportTypes.value
      : [ReportType.TIME, ReportType.SICK, ReportType.DETACHEDTIME],
  };
  reportsStore.getReportsByPage(params).finally(() => (loadingReports.value = false));
  getCalendarReportsAndPublicHolidaysPerMonth(currentMonth.value, true);
  getRelevantVacationAndAbsenceReports();
  getRelevantParentalReports();
};

const handleReportWithBusinessDayCheck = (
  report: ReportPayloadInterface,
  reportFunc: (report: ReportPayloadInterface) => void
): void => {
  (report.type === ReportType.TIME || report.type === ReportType.SICK || ReportType.DETACHEDTIME) &&
  duringNonBusinessDay(report)
    ? confirmNonBusinessDayModal(report)
        .then(() => reportFunc(report))
        .catch(() => {})
    : reportFunc(report);
};

const handleStoreReport = (report: ReportPayloadInterface): void => {
  reportsStore
    .storeReport(report)
    .then((res: any) => {
      if (res.prepaid_message) {
        emitter.emit("show-prepaid-create-warning", {
          report: report,
          warning: res.prepaid_message,
        });
      } else if (res.sick_report_warning) {
        emitter.emit("show-sick-overlap-warning", {
          report: report,
          warning: res.sick_report_warning,
        });
      } else if (res.time_report_warning) {
        emitter.emit("show-time-overlap-warning", {
          report: report,
          warning: res.time_report_warning,
        });
      } else if (res.absence_report_warning) {
        emitter.emit("show-absence-report-warning", {
          report: report,
          warning: res.absence_report_warning,
        });
      } else if (res?.vacation_split_count > 0) {
        const warningMessage = ref<String>("Report saved successfully!");
        if (report.type == "sick-report") {
          warningMessage.value += " An existing vacation report has been modified.";
        } else if (report.type == "vacation-report") {
          warningMessage.value += " The report has been split into two or more separate reports";
        }
        $message?.warning(warningMessage.value, 3);
        reloadAllReports();
      } else {
        $message?.success("Report saved successfully!", 3);
        reloadAllReports();
      }
    })
    .catch((error) => {
      if (error.status == 422 && error.data.message) {
        $message?.error(error.data.message, 5);
      } else {
        $message?.error("Couldn't save report!", 3);
      }
    });
};

const handleUpdateReport = (updatedReport: ReportPayloadInterface): void => {
  reportsStore
    .updateReport({ reportId: updatedReport.id, updatedReport })
    .then((res: any) => {
      if (res.prepaid_message) {
        emitter.emit("show-prepaid-update-warning", updatedReport, res.prepaid_message);
      } else if (res?.vacation_split_count) {
        const warningMessage = ref<String>("Report updated successfully!");
        if (updatedReport.type == "sick-report") {
          warningMessage.value += " An existing vacation report has been modified.";
        } else if (updatedReport.type == "vacation-report") {
          warningMessage.value += " The report has been split into two or more separate reports";
        }
        $message?.warning(warningMessage.value, 3);
        reloadAllReports();
      } else {
        $message?.success("Report updated successfully!", 3);
        reloadAllReports();
      }
    })
    .catch((error) => {
      if (error.status == 403 && error.data.message) {
        $message?.error(error.data.message, 5);
      } else {
        $message?.error("Couldn't save report!", 3);
      }
    });
};

const handleDestroyReport = (reportId: number): void => {
  reportsStore
    .destroyReport(reportId)
    .then(() => {
      $message?.success("Report removed successfully!", 3);
      reloadAllReports();
    })
    .catch(() => $message?.error("Couldn't remove report!", 3));
};

const handleStoreSurveyResponse = (survey: SurveyPayloadInterface): void => {
  reportsStore
    .storeSurveyResponse(survey)
    .then((res: any) => {
      $message?.success("Form response saved successfully! Thank you for answering! :)", 8);
    })
    .catch((error: any) => {
      if (error.request.status == 422) {
        $message?.error("You need to fill in the feedback form before submitting!", 8);
      } else {
        $message?.error("Couldn't save form response!", 8);
      }
    });
};

const copyReportToForm = (report: ReportInterface): void => {
  reportType.value = report.type ?? ReportType.TIME;
  reportToCopyFrom.value = report;
};

const duringNonBusinessDay = (report: ReportPayloadInterface): boolean => {
  const monthPublicHolidays = publicHolidays.value.get(currentMonth.value);
  if (monthPublicHolidays === undefined || !report.start_date) {
    return false;
  }

  const start = new Date(report.start_date);
  const onPublicHoliday = monthPublicHolidays.some(
    (ph: PublicHolidayInterface) => new Date(ph.date).getTime() === start.getTime()
  );
  const onWeekend = isWeekendDay(start);
  return onPublicHoliday || (onWeekend && report.type !== ReportType.TIME);
};

const confirmNonBusinessDayModal = (report: any): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    $confirm?.({
      title: "This report is on a non-business day (a public holiday or a weekend)",
      content: "Do you wish to proceed?",
      okText: "Proceed",
      cancelText: "Cancel",
      onOk: () => resolve(),
      onCancel: () => reject(),
    });
  });
};
</script>
