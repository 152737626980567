<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="'Create new Transaction'"
    ok-text="Create"
    @ok="() => handleStoreTransaction()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal" data-vv-scope="newTransaction">
      <!-- TAB SELECTOR -->
      <a-form-item class="d-flex justify-content-center">
        <a-radio-group
          default-value="salary-transaction"
          @change="(el) => handleTypeChange(el.target.value)"
        >
          <a-radio-button value="salary-transaction">Salary</a-radio-button>
          <a-radio-button value="receipt-transaction">Receipt</a-radio-button>
          <a-radio-button value="vacation-transaction">Vacation</a-radio-button>
          <a-radio-button value="plain-transaction">Plain</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <SkNameSelect
        v-model="newTransaction.user_id"
        v-validate="'required'"
        :error="$validator.errors.first('newTransaction.user')"
        data-vv-name="user"
        label="User"
        :label-col-size="7"
        :wrapper-col-size="12"
        placeholder="Select user"
        :options="users"
        @change="(userId) => $emit('get-user', userId)"
      />

      <a-form-item
        v-if="isOfType('receipt')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.receipt') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.receipt')"
        label="Receipt"
      >
        <a-select
          v-model="newTransaction.receipt_id"
          show-search
          option-filter-prop="children"
          placeholder="Select a receipt"
        >
          <a-select-option
            v-for="r in receipts"
            :key="r.id"
            v-validate="'required'"
            :value="r.id"
            data-vv-name="receipt"
          >
            {{ `${toMoney(r.amount)} ${r.created_at}` }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.amount_cents') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.amount_cents')"
        label="Amount"
      >
        <a-input
          v-model="newTransaction.amount_cents"
          v-validate="'required'"
          :addon-after="newTransaction.amount_currency"
          data-vv-name="amount"
          placeholder="Enter amount"
        >
          <template #addonBefore>
            <a-select
              v-if="isOfType('receipt') || isOfType('plain')"
              v-model="recordedAs"
              default-value="negative"
              style="width: 50px"
            >
              <a-select-option value="negative">-</a-select-option>
              <a-select-option value="positive">+</a-select-option>
            </a-select>
            <span v-else>-</span>
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.made-at') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.made-at')"
        label="Made at"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newTransaction.made_at)"
          placeholder="Date"
          class="w-100"
          data-vv-name="made_at"
          data-vv-as="date"
          @change="(date) => onDateChange(date, 'made_at')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" label="Status">
        <a-radio-group
          :value="newTransaction.status"
          @change="(el) => (newTransaction.status = el.target.value)"
        >
          <a-radio-button value="pending">Pending</a-radio-button>
          <a-radio-button value="paid">Paid</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.description') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.description')"
        label="Description"
      >
        <a-textarea
          v-model="newTransaction.description"
          v-validate="'required'"
          :auto-size="{ minRows: 3 }"
          data-vv-name="description"
          placeholder="Enter description"
        />
      </a-form-item>

      <a-form-item
        v-if="isOfType('salary') || isOfType('vacation')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="
          $validator.errors.first('newTransaction.payroll_tax_percent') ? 'error' : ''
        "
        :help="$validator.errors.first('newTransaction.payroll_tax_percent')"
        label="Payroll tax"
      >
        <a-input-number
          v-model="newTransaction.payroll_tax_percent"
          v-validate="'required|min_value:0|max_value:100'"
          :precision="2"
          data-vv-name="payroll_tax_percent"
          data-vv-as="payroll tax"
          placeholder="Enter payroll tax"
        />
      </a-form-item>

      <a-form-item
        v-if="isOfType('salary') || isOfType('vacation')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="
          $validator.errors.first('newTransaction.income_tax_percent') ? 'error' : ''
        "
        :help="$validator.errors.first('newTransaction.income_tax_percent')"
        label="Income tax"
      >
        <a-input-number
          v-model="newTransaction.income_tax_percent"
          v-validate="'required|min_value:0|max_value:100'"
          :precision="2"
          data-vv-name="income_tax_percent"
          data-vv-as="income tax"
          placeholder="Enter income tax"
        />
      </a-form-item>

      <a-form-item
        v-if="isOfType('salary')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.vacation_percent') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.vacation_percent')"
        label="Vacation"
      >
        <a-input-number
          v-model="newTransaction.vacation_percent"
          v-validate="'required|min_value:0|max_value:100'"
          :precision="2"
          data-vv-name="vacation_percent"
          data-vv-as="vacation"
          placeholder="Enter vacation"
        />
      </a-form-item>

      <a-form-item
        v-if="isOfType('salary')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        label="Sick days"
      >
        <a-input-number
          v-model="newTransaction.sick_days"
          v-validate="'min_value:0|max_value:31'"
          :precision="0"
          data-vv-name="sick_days"
          data-vv-as="sick days"
          placeholder="0"
          disabled
        />
      </a-form-item>
      <a-form-item
        v-if="isOfType('salary')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        label="VAB days"
      >
        <a-input-number
          v-model="newTransaction.vab_days"
          v-validate="'min_value:0|max_value:31'"
          :precision="0"
          data-vv-name="vab_days"
          data-vv-as="vab days"
          placeholder="0"
          disabled
        />
      </a-form-item>
      <a-form-item
        v-if="isOfType('salary')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        label="Absence days"
      >
        <a-input-number
          v-model="newTransaction.absence_days"
          v-validate="'min_value:0|max_value:31'"
          :precision="0"
          data-vv-name="absence_days"
          data-vv-as="absence days"
          placeholder="0"
          disabled
        />
      </a-form-item>
      <a-form-item
        v-if="isOfType('salary')"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        label="Parental days"
      >
        <a-input-number
          v-model="newTransaction.parental_days"
          v-validate="'min_value:0|max_value:31'"
          :precision="0"
          data-vv-name="parental_days"
          data-vv-as="parental days"
          placeholder="0"
          disabled
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { ProfileInterface } from "../../authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { ReceiptInterface } from "../../receipts/types";
import { TransactionPayloadInterface } from "../types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";
import { apiGetReportMonthSummary } from "../../reports/_utils/api";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  receipts: { type: Array as () => Array<ReceiptInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-receipts", "get-users", "store-transaction", "close"]);

// Mixins
const { setObject, toMoneyCents, toMoney } = useMixin();

// Filters
const { parseDate } = useFilters();

// instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const recordedAs = ref<string>("negative");
const reports_month_summary = ref<
  undefined | { sick_days: number; vab_days: number; absence_days: number; parental_days: number }
>();
const newTransaction = ref<TransactionPayloadInterface>({
  type: "salary-transaction",
  user_id: undefined,
  amount_cents: undefined,
  amount_currency: undefined,
  made_at: undefined,
  status: "pending",
  description: undefined,
  receipt_id: undefined,
  payroll_tax_percent: undefined,
  income_tax_percent: undefined,
  vacation_percent: undefined,
  sick_days: undefined,
  vab_days: undefined,
  parental_days: undefined,
  absence_days: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      resetTransaction();
      emits("get-users");
    }
  }
);

watch(
  () => reports_month_summary.value,
  (newProp) => {
    newTransaction.value.sick_days = newProp.sick_days;
    newTransaction.value.absence_days = newProp.absence_days;
    newTransaction.value.vab_days = newProp.vab_days;
    newTransaction.value.parental_days = newProp.parental_days;
  }
);

watch(
  () => props.user,
  (newProp) => {
    if (newTransaction.value.type === "salary-transaction") {
      handleGetReportDays(newProp.id, newTransaction.value.made_at);
    }

    if (newProp.payroll_tax_percent) {
      newTransaction.value.payroll_tax_percent = castToPercentage(newProp.payroll_tax_percent);
    }
    if (newProp.income_tax_percent) {
      newTransaction.value.income_tax_percent = castToPercentage(newProp.income_tax_percent);
    }
    if (newProp.vacation_percent) {
      newTransaction.value.vacation_percent = castToPercentage(newProp.vacation_percent);
    }
    newTransaction.value.amount_currency = props.user.skp_currency;
    if (newTransaction.value.type === "receipt-transaction") {
      emits("get-receipts", props.user.id);
    }
  }
);

// Component methods
// Determine if the transaction that is being created is of the given type.
const isOfType = (type: string): boolean => {
  return newTransaction.value.type === `${type}-transaction`;
};

const onDateChange = (value: any, attribute: string): void => {
  newTransaction.value[attribute] = value ? value.format("YYYY-MM-DD") : undefined;
  if (newTransaction.value.type === "salary-transaction") {
    handleGetReportDays(newTransaction.value.user_id, newTransaction.value.made_at);
    newTransaction.value.description = `Salary ${value.format("MMMM YYYY")}`;
  }
};

const resetTransaction = (): void => {
  recordedAs.value = "negative";
  newTransaction.value = setObject(newTransaction.value, undefined);
  newTransaction.value.type = "salary-transaction";
  newTransaction.value.description = `Salary ${moment().format("MMMM YYYY")}`;
  newTransaction.value.status = "pending";
  newTransaction.value.made_at = moment().date(25).format("YYYY-MM-DD");
};

// Triggered when the type of transaction is changed
const handleTypeChange = (value: string): void => {
  resetTransaction();
  newTransaction.value.type = value;

  // Only salary-transaction has default description
  if (value !== "salary-transaction") newTransaction.value.description = undefined;

  newTransaction.value.made_at = setDefaultDate(value);

  // Fetch receipts
  if (value === "receipt-transaction" && props.receipts.length === 0) {
    emits("get-receipts");
  }
};

// Set a custom default date for salary and plain transactions
const setDefaultDate = (value: string): string => {
  let date = moment().format("YYYY-MM-DD");
  if (value === "plain-transaction") date = moment().date(1).format("YYYY-MM-DD");
  else if (value === "salary-transaction") date = moment().date(25).format("YYYY-MM-DD");

  return date;
};

const castToPercentage = (value: number): number | undefined => {
  return value ? Math.round(value * 10000) / 100 : undefined;
};

const castToDecimal = (value: number): number => {
  return value ? value / 100 : 0;
};

const changeAmountToCents = (): void => {
  newTransaction.value.amount_cents = toMoneyCents(newTransaction.value.amount_cents ?? 0);
};

const handleStoreTransaction = (): void => {
  $validator?.validateAll("newTransaction").then((result) => {
    if (result) {
      changeAmountToCents();
      if (newTransaction.value.payroll_tax_percent)
        newTransaction.value.payroll_tax_percent = castToDecimal(
          newTransaction.value.payroll_tax_percent
        );
      if (newTransaction.value.income_tax_percent)
        newTransaction.value.income_tax_percent = castToDecimal(
          newTransaction.value.income_tax_percent
        );
      if (newTransaction.value.vacation_percent)
        newTransaction.value.vacation_percent = castToDecimal(
          newTransaction.value.vacation_percent
        );

      // Check sign of amount and convert it to the correct one
      // specified by the user
      if (isOfType("salary") || isOfType("vacation") || recordedAs.value === "negative") {
        if (newTransaction.value.amount_cents)
          newTransaction.value.amount_cents = -newTransaction.value.amount_cents;
      }
      emits("store-transaction", newTransaction.value);
      emits("close");
    } else {
      // Reset amount to a positive number.
      // Sign checking happens at the create transaction state.
      if (newTransaction.value.amount_cents !== undefined) {
        if (newTransaction.value.amount_cents)
          newTransaction.value.amount_cents = Math.abs(newTransaction.value.amount_cents);
      }
    }
  });
};
const handleGetReportDays = (userId: number, madeAt: string): void => {
  apiGetReportMonthSummary(userId, getYearMonth(madeAt)).then((res: any) => {
    reports_month_summary.value = res.data;
  });
};

const getYearMonth = (madeAt: string) => {
  const momentDate = moment(madeAt).subtract(1, "months");
  const formattedDate = momentDate.format("YYYY.M");

  return formattedDate;
};
</script>
